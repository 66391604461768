import React from "react";
import FixedScreen from "../components/fixedScreen";

class Schedule extends React.Component {
  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }
  render() {
    return (
      <FixedScreen>
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/ajalbawab"
          style={{
            minWidth: "320px",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
          }}
        />
      </FixedScreen>
    );
  }
}

export default Schedule;
