import React from "react";
import FixedScreen from "../components/fixedScreen";
import PasswordCalendar from "../components/passwordCalendar";

const Calendar = () => {
  return (
    <FixedScreen>
      <PasswordCalendar />;
    </FixedScreen>
  );
};

export default Calendar;
