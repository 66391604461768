import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../pages/home";
import About from "../pages/about";
import Calendar from "../pages/calendar";
import Schedule from "../pages/schedule";

const Main = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home}></Route>
      <Route exact path="/about" component={About}></Route>
      <Route exact path="/calendar" component={Calendar}></Route>
      <Route exact path="/schedule" component={Schedule}></Route>
    </Switch>
  );
};

export default Main;
