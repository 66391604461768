import React from "react";

const FixedScreen = (props) => {
  return (
    <div
      style={{
        height: "100vh",
        overflow: "hidden",
        position: "absolute",
        width: "100%",
        top: 0,
      }}
    >
      <div style={{ height: 58 }}></div>
      {props.children}
    </div>
  );
};

export default FixedScreen;
