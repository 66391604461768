import React from "react";
import FixedScreen from "../components/fixedScreen";
import "../css/home.css";

const Home = () => {
  return (
    <FixedScreen>
      <div className="center_container">
        <section className="main_container">
          <div
            className="ccard"
            onClick={() => {
              window.location = "https://ajalbawab.com/about";
            }}
          >
            <img className="homeimg personalAJ" src="logo512.png" alt="" />
            <article className="content">
              <h3 className="headers3">Personal Website</h3>
              <p className="paragraph_text">You're Here! Click Me.</p>
            </article>
          </div>

          <div
            className="ccard"
            onClick={() => {
              window.location = "https://networkvice.com/";
            }}
          >
            <img className="homeimg" src="cloud_service.png" alt="" />
            <article className="content">
              <h3 className="headers3">Personal Drive</h3>
              <p className="paragraph_text">
                A personal cloud-storage network for minimizing CPU harddrive
                space.
              </p>
            </article>
          </div>

          <div
            className="ccard"
            onClick={() => {
              window.location = "https://acoffeechat.com/";
            }}
          >
            <img className="homeimg" src="coffee_chat.png" alt="" />
            <article className="content">
              <h3 className="headers3">Coffee Chat</h3>
              <p className="paragraph_text">
                Take a look into the App I'm currently working on.
              </p>
            </article>
          </div>

          <div
            className="ccard"
            onClick={() => {
              window.location = "https://albawabthoughts.com";
            }}
          >
            <img className="homeimg" src="albawab_thoughts.png" alt="" />
            <article className="content">
              <h3 className="headers3">Personal Blog</h3>
              <p className="paragraph_text">A simple / self hosted PHP blog</p>
            </article>
          </div>
        </section>
      </div>
    </FixedScreen>
  );
};

export default Home;
