import React from "react";
import CalendarComponent from "./calendar";

class PasswordCalendar extends React.Component {
  state = {
    modal: true,
    modalPassword: "aj",
    error: "",
  };

  componentDidMount() {
    let cookies_expire = document.cookie;
    console.log(cookies_expire);
    if (cookies_expire) return this.setState({ modal: false });
    return this.setState({ modal: true });
  }

  writeCookieExpires = () => {
    var now = new Date();
    now.setTime(now.getTime() + 0.01 * 3600 * 1000);
    document.cookie = "name=success; expires=" + now.toUTCString() + ";";
  };

  modalState = () => {
    var password = this.tempPassword.value;
    if (this.state.modalPassword === password) {
      this.setState({ modal: false });

      this.writeCookieExpires();
    } else {
      return this.setState({ error: "Password is incorrect!" });
    }
  };

  handleEnter = (e) => {
    if (e.charCode === 13) {
      this.modalState();
    }
  };

  render() {
    if (this.state.modal)
      return (
        <div
          className="modal modal-signin position-static d-block bg-secondary py-5"
          tabindex="-1"
          role="dialog"
          id="modalSignin"
        >
          <div className="modal-dialog" role="document" style={{}}>
            <div className="modal-content rounded-5 shadow">
              <div className="modal-header p-5 pb-4 border-bottom-0">
                <h2 className="fw-bold mb-0">
                  Enter Password to view my Master Calendar
                </h2>
              </div>

              <div className="modal-body p-5 pt-0">
                <p style={{ color: "red" }}>{this.state.error}</p>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    name="password"
                    className="form-control rounded-4"
                    id="floatingPassword"
                    placeholder="Password"
                    ref={(c) => (this.tempPassword = c)}
                    onKeyPress={this.handleEnter}
                  ></input>
                  <label>Password</label>
                </div>
                <button
                  type="button"
                  className="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                  onClick={this.modalState}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    else {
      return <CalendarComponent />;
    }
  }
}

export default PasswordCalendar;
