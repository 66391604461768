import React from "react";
import SlideShow from "../components/slideshow";
import "../css/about.css";
import image1 from "../images/about_3.jpg";
import image2 from "../images/about_4.jpg";
import image3 from "../images/about_5.jpg";
import { BsLinkedin, BsInstagram, BsGithub } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { FaAddressCard } from "react-icons/fa";

class About extends React.Component {
  state = {
    firsth1pos: 30,
    firsth1color: "black",
    secondh1pos: 30,
    secondh1color: "black",
    thirdh1pos: 30,
    thirdh1color: "black",
    fourthh1pos: 30,
    fourthh1color: "black",
  };

  listenScrollEvent = (e) => {
    if (window.scrollY === 0) {
      this.setState({
        firsth1color: "black",
      });
    }
    if (window.scrollY < 100) {
      this.setState({
        firsth1color: "black",
      });
    }

    if (window.scrollY > 100) {
      this.setState({
        firsth1color: "white",
      });
    }

    if (window.scrollY > 400) {
      this.setState({
        firsth1color: "white",
        secondh1color: "black",
      });
    }
    if (window.scrollY > 600) {
      this.setState({
        firsth1color: "black",
        secondh1color: "grey",
      });
    }
    if (window.scrollY > 900) {
      this.setState({
        secondh1color: "white",
      });
    }
    if (window.scrollY > 1550) {
      this.setState({
        secondh1color: "black",
        thirdh1color: "white",
        fourthh1color: "black",
      });
    }
    if (window.scrollY > 2700) {
      this.setState({
        thirdh1color: "black",
        fourthh1color: "white",
      });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    window.onscroll = () => {
      let pos = window.scrollY;

      this.setState({ firsth1pos: (pos * -1) / 20 + 20 });
      this.setState({ secondh1pos: (pos * -1) / 20 + 60 });
      this.setState({ thirdh1pos: (pos * -1) / 20 + 120 });
      this.setState({ fourthh1pos: (pos * -1) / 20 + 190 });
    };
    const CreateVCard = () => {
      var vCardsJS = require("vcards-js");

      //create a new vCard
      var vCard = vCardsJS();

      //set properties
      vCard.firstName = "AJ";
      vCard.lastName = "Al-Bawab";
      vCard.organization = "Truist Securities";
      vCard.photo.attachFromUrl(
        "https://avatars.githubusercontent.com/u/68740075?v=4",
        "JPEG"
      );

      vCard.cellPhone = "678-447-6254";
      vCard.birthday = new Date(1995, 4, 11);
      vCard.title = "Credit Delivery Analyst";
      vCard.email = "ajalbawab@gmail.com";
      vCard.url = "https://ajalbawab.com/";
      vCard.note =
        "Chicken bowl, black beans, brown rice, fajita veggies, mild salsa, guacamole, and lettuce.";

      const FileSaver = require("file-saver");
      const blob = new Blob([vCard.getFormattedString()], {
        type: "text/vcard;charset=utf-8",
      });
      FileSaver.saveAs(blob, "ajalbawab.vcf");
    };
    return (
      <div
        style={{
          position: "relative",
          top: 56,
          background: "black",
          overflow: "hidden",
        }}
      >
        <div>
          <div className="header_section">
            <p className="header_title">AJ Al-Bawab</p>
            <div className="socials_container">
              <a
                href="https://www.linkedin.com/in/ajalbawab/"
                target="_blank"
                rel="noreferrer"
                className="linkedin_con"
              >
                <BsLinkedin
                  className="social linkedin"
                  style={{ color: "white" }}
                />
                <BsLinkedin className="social linkedin_hide" />
              </a>
              <a
                href="https://www.instagram.com/ajalbawab/?hl=en"
                target="_blank"
                rel="noreferrer"
                className="instagram_con"
              >
                <BsInstagram
                  className="social instagram"
                  style={{ color: "white" }}
                />
                <BsInstagram className="social instagram_hide" />
              </a>
              <a
                href="https://github.com/ajalbawab"
                target="_blank"
                rel="noreferrer"
                className="github_con"
              >
                <BsGithub
                  className="social github"
                  style={{ color: "white" }}
                />
                <BsGithub className="social github_hide" />
              </a>
              <div onClick={() => CreateVCard()} className="vcard_con">
                <FaAddressCard
                  className="social vcard"
                  style={{ color: "white" }}
                />
                <FaAddressCard
                  className="social vcard_hide"
                  style={{ color: "#0d6efd" }}
                />
              </div>
              <a
                href="mailto:ajalbawab@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="email_con"
              >
                <MdOutlineEmail
                  className="social email"
                  style={{ color: "white" }}
                />
                <MdOutlineEmail className="social email_hide" />
              </a>
            </div>
          </div>

          <div>
            <span>
              <h1
                className="headerPage"
                style={{
                  position: "relative",

                  left: `${this.state.firsth1pos}vw`,
                  color: this.state.firsth1color,
                  transition: "linear",
                  transitionDuration: ".2s",
                }}
              >
                Mission and Goal
              </h1>
            </span>

            <div className="section_2">
              <section className="section_2_block1">
                <img alt="" src="about_2.png" className="clock_img"></img>
              </section>
              <section className="section_2_block2">
                <div className="vision_block_inside">
                  <h3>Mission and Goal</h3>
                  <p className="vision_block2_p">
                    One of my person greatest obstacles in life is time -
                    specifically the illusion that time feels as if it is moving
                    faster the older we become. My mission and goal throughout
                    life is to be able to afford time - more specifically to be
                    able to enjoy life's greatest moments. With each day that
                    passes, I hope to learn at least one thing that day.
                  </p>
                </div>
              </section>
            </div>
            <span>
              <h1
                className="headerPage"
                style={{
                  position: "relative",
                  fontSize: "3rem",
                  left: `${this.state.secondh1pos}vw`,
                  color: this.state.secondh1color,
                  transition: "linear",
                  transitionDuration: ".2s",
                }}
              >
                Upbringing and Education
              </h1>
            </span>
            <div className="section_3">
              <section className="section_3_block1">
                <div className="education_block_inside">
                  <h3>Upbringing and Education</h3>
                  <p className="education_block2_p">
                    After highschool, I started volunteering at a local church
                    learning audio/visual for services. Little did I know this
                    would turn into me enrolling in an audio school called "SAE"
                    in downtown Atlanta. I commuted an hour and a half each way
                    just to learn audio while starting to contract at the same
                    local church. The years following turned into a full-blown
                    career where I was relying financially on contract work from
                    big corporate events, Sunday's at church, and even touring
                    the country with radio country artists. I enjoyed 100% of
                    this new-found passion, but I was starting to feel the
                    burden of financial expectation. Fast forward to 2017, and I
                    have enrolled full time for Finance at the University of
                    North Georgia while also working full time and traveling
                    across the US. The analytical and technical side of audio
                    aided in the learning curve in school where I received a 4.0
                    every semester since starting college. In 2019, I
                    transferred to Georgia State University in Atlanta and
                    continued my education.
                  </p>
                </div>
              </section>
              <section className="section_3_block2">
                <img alt="" src={image1} className="clock_img"></img>
              </section>
            </div>
            <span>
              <h1
                className="headerPage"
                style={{
                  position: "relative",
                  fontSize: "3rem",
                  left: `${this.state.thirdh1pos}vw`,
                  color: this.state.thirdh1color,
                  transition: "linear",
                  transitionDuration: ".2s",
                }}
              >
                Professional Experience
              </h1>
            </span>
            <div className="section_4">
              <section className="section_4_block1">
                <div className="education_block_inside">
                  <h3>Professional Experience</h3>
                  <p className="education_block2_p">
                    My understanding of college was limited to the basic process
                    of graduating and looking for a job after graduation. Once I
                    learned how valuable inter-college experience was, I
                    embarked on multiple internships. My first kick-off into
                    internships was split between two businesses. Starting off
                    at an acquisitions advisory firm in Atlanta while also
                    working in the finance department of a sponsor-backed
                    software startup was extremely valuable in piecing together
                    the equity owned investment world with the corporate side.
                    Those two internships led to an internship at a hedge fund
                    in Atlanta that works closely with the Lloyds of London
                    market. Providing analysis and market research developed
                    financial modeling and pitching skills. After this
                    internship, I joined a Fixed Income trading desk in Atlanta
                    learning code from scratch and helping processes within the
                    firm. In Summer of 2021, I joined Truist Securities for
                    their Corporate and Investment Bank Summer Analyst Program
                    to tie all my prior experiences together. Starting January
                    2022, I'll be joining Truist full time in the Analyst
                    Program within the Credit Delivery group.
                  </p>
                </div>
              </section>
              <section className="section_4_block2">
                <img alt="" src={image2} className="clock_img"></img>
              </section>
              <span>
                <h1
                  className="headerPage"
                  style={{
                    position: "relative",
                    left: `${this.state.fourthh1pos}vw`,
                    color: this.state.fourthh1color,
                    transition: "linear",
                    transitionDuration: ".2s",
                  }}
                >
                  Hobbies and Interests
                </h1>
              </span>
              <div className="section_5">
                <section className="section_5_block2">
                  <img alt="" src={image3} className="clock_img"></img>
                </section>
                <section className="section_5_block1">
                  <div className="education_block_inside">
                    <h3>Hobbies and Interests</h3>
                    <p className="education_block2_p">
                      The software startup was a small team where I taught
                      myself VBA and created spreadsheets that helped automate
                      much of the back-office processes in place. That led to
                      further development at the Fixed Income trading desk where
                      I started to teach myself Python. What started out as a
                      personal curiosity became a personal fixation. I wanted to
                      learn EVERYTHING in this new sandbox. I started building
                      simple robots with Raspberry Pi's and Servo motors. I
                      created robots that scraped the SEC's EDGAR for insider
                      trading. I learned how to quantitatively trade equities
                      and even learned how to increase engagement on TikTok.
                      This curiosity further developed into learning how to
                      build my own website, utilize a simple DB solution (SQL),
                      and create iOS and Android applications from scratch!
                      React-Native, NodeJS, Javascript, React, SQL and Python
                      become the new tools for me to quench this insatiable
                      thirst and curiosity within Software Development. To give
                      an example, this website was built from scratch using
                      React. Put plainly, I don't really watch Netflix.
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <SlideShow />
      </div>
    );
  }
}

export default About;
