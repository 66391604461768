import ss1 from "../images/ss1.png";
import ss2 from "../images/ss2.png";
import ss3 from "../images/ss3.png";
const images = [ss1, ss2, ss3];

const slides = [
  <div className="slide">
    <div className="inner_slide_container">
      <img className="inner_slide_image" src={images[0]} alt="" />
      <div className="inner_slide_textbox">
        <h4 className="inner_slide_header">AJ</h4>
        <p className="inner_slide_text">
          Finance Student at Georgia State University <br />
          <br />
          AJ Al-Bawab is a systems-oriented, driven, intuitively curious
          financière and engineer on track to graduate Summa Cum Laude with a
          Bachelors in Business Administration from Georgia State University in
          Spring 2021. <br /> <br />
          Born in Dubai, AJ has developed a unique perspective for intercultural
          empathy. By deeply understanding the people around him, he finds ease
          in understanding their risk profiles. Through understanding the depths
          of financial analysis, AJ’s passion grows towards helping people and
          companies flourish financially. These tools allow AJ to think
          creatively, solve efficiently, and act logically.
        </p>
      </div>
    </div>
  </div>,
  <div className="slide">
    <div className="inner_slide_container">
      <img className="inner_slide_image" src={images[1]} alt="" />
      <div className="inner_slide_textbox">
        <h4 className="inner_slide_header">AJ</h4>
        <p className="inner_slide_text">
          Finance Student at Georgia State University <br />
          <br />
          Curiosity and drive continue to define the day to day activities AJ
          pursues. While in school full time, one can find him travelling the
          country running audio for bands, learning finance from internships at
          corporations and financial institutions, completing educational equity
          research within the TMT or Energy sectors, and volunteering as a
          finance tutor for his classmates.
        </p>
      </div>
    </div>
  </div>,
  <div className="slide">
    <div className="inner_slide_container">
      <img className="inner_slide_image" src={images[2]} alt="" />
      <div className="inner_slide_textbox">
        <h4 className="inner_slide_header">AJ</h4>
        <p className="inner_slide_text">
          Finance Student at Georgia State University <br />
          <br />
          This fusion of drive, curiosity, and intuition creates a unique edge
          that becomes his secret sauce! He continues to grow intellectually
          every day by learning from those around him always. <br /> <br />
          AJ is looking for opportunities within the Investment Banking sector
          specifically within leveraged finance or M&A advisory.
        </p>
      </div>
    </div>
  </div>,
];

export default slides;
